import React from "react";

import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";

interface ImpressionTrackerProps {
  postId: string;
  children: React.ReactNode;
  className?: string;
}

export const ImpressionTracker: React.FC<ImpressionTrackerProps> = ({
  postId,
  children,
  className,
}) => {
  const ref = useIntersectionObserver({ postId });

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
};
