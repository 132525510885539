import axios, { AxiosRequestConfig } from "axios";

import {
  addCommonRequestInterceptor,
  addCommonResponseInterceptor,
} from "./interceptors";
import { getHttpUrlRoot } from "./utils";

export interface CommonRes<T> {
  data?: T;
  message: string;
  code?: number;
}

export type CommonError = {
  message: string;
  rawMessage: string | string[];
  errorCode?: number;
  statusCode: number;
};

export const axiosInstance = async () => {
  const baseURL = getHttpUrlRoot();

  const instance = axios.create({
    baseURL,
    timeout: 20000,
  });

  // Add a request interceptor
  addCommonRequestInterceptor(instance);

  // Add a response interceptor
  addCommonResponseInterceptor(instance);

  return instance;
};

export const get = async <T>(url: string, config?: AxiosRequestConfig) =>
  (await axiosInstance())
    .get<CommonRes<T>>(url, config)
    .then((res) => res.data);

export const post = async <T>(
  url: string,
  body?: any,
  config?: AxiosRequestConfig,
) =>
  (await axiosInstance()).post<CommonRes<T>>(url, body, config).then((res) => {
    return res.data;
  });

export const del = async <T>(url: string, config?: AxiosRequestConfig) =>
  (await axiosInstance()).delete<CommonRes<T>>(url, config).then((res) => {
    return res.data;
  });

export const put = async <T>(
  url: string,
  body?: any,
  config?: AxiosRequestConfig,
) =>
  (await axiosInstance()).put<CommonRes<T>>(url, body, config).then((res) => {
    return res.data;
  });
