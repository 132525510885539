var ObjectId = require('bson').ObjectId;

const VISITOR_ID_KEY = "brek-visitor-id";

const isClient =
  typeof window !== "undefined" && typeof localStorage !== "undefined";

export const getOrCreateVisitorId = (): string | undefined => {
  if (!isClient) {
    console.log("getOrCreateVisitorId is not client");
    return undefined;
  }

  let visitorId = localStorage.getItem(VISITOR_ID_KEY) || '';

  // If no visitor id, or if visitor id is a legacy uuid (by checking if there's a dash in the id)
  // create a new visitor id. Note: this only works for the client side, we have seperate logic to 
  // create a visitor id on the server side for SSR.
  if (!visitorId || visitorId === '' || visitorId.includes('-')) {
    visitorId = new ObjectId().toString();
    localStorage.setItem(VISITOR_ID_KEY, visitorId)
  }

  return visitorId;
}
