import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { RiHeartLine } from "react-icons/ri";

import { PostDetail } from "@/types/api/post";
import { pickImageSize } from "@/utils/image";

import Avatar from "../Avatar";

const MAX_THUMB_HEIGHT = 300;

type Props = {
  index?: number;
  feed: PostDetail;
  onPostClick?: () => void;
};

const PostListItem = ({ index, feed, onPostClick }: Props) => {
  const thumbnail = pickImageSize(feed.thumbnail);
  const [imageHeight, setImageHeight] = useState(MAX_THUMB_HEIGHT);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateImageHeight = () => {
      if (containerRef.current && thumbnail?.width && thumbnail?.height) {
        const containerWidth = containerRef.current.offsetWidth;
        const aspectRatio = thumbnail.width / thumbnail.height;
        const calculatedHeight = Math.round(containerWidth / aspectRatio);
        setImageHeight(Math.min(calculatedHeight, MAX_THUMB_HEIGHT));
      }
    };

    updateImageHeight();
    window.addEventListener("resize", updateImageHeight);

    return () => {
      window.removeEventListener("resize", updateImageHeight);
    };
  }, [thumbnail]);

  return (
    <div className="w-full" ref={containerRef}>
      <Link
        href={`/post/${feed.postId}`}
        key={feed.postId}
        className="block w-full"
        onClick={onPostClick}
      >
        <div className="relative w-full" style={{ height: `${imageHeight}px` }}>
          <Image
            src={thumbnail?.url || ""}
            alt={feed.title}
            fill
            className="rounded-xl bg-gray-300 object-cover"
          />
        </div>
        <div className="px-0 pb-2 pt-1">
          <h2 className="line-clamp-2 py-1 text-base font-medium">
            {feed.title}
          </h2>
          <div className="flex items-center pt-1">
            <Avatar url={pickImageSize(feed.author.avatar)?.url} size={18} />
            <span className="ml-1 flex-grow truncate text-sm text-gray-600">
              {feed.author.userDisplayName}
            </span>
            <div className="flex items-center">
              <RiHeartLine className="h-4 w-4 text-gray-600" />
              <span className="ml-1 text-sm text-gray-600">
                {feed.numberOfLikes}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PostListItem;
