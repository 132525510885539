const PostListItemSkeleton = () => {
  return (
    <div className="block w-full">
      {/* image */}
      <div className="relative h-60 w-full animate-pulse rounded-lg bg-gray-300">
        <div className="h-full w-full rounded-lg"></div>
      </div>

      {/* title */}
      <div className="mt-2 h-4 w-full animate-pulse rounded bg-gray-300"></div>

      <div className="mt-2 flex items-center">
        {/* avatar */}
        <div className="h-4 w-4 animate-pulse rounded-full bg-gray-300"></div>
        {/* author name */}
        <div className="ml-1 h-4 w-16 animate-pulse rounded bg-gray-300"></div>
      </div>
    </div>
  );
};

export default PostListItemSkeleton;
