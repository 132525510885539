import { ImageDto, ImageSizeType } from "@/types/api/media";

/**
 * pick one size from image sizes
 *
 * if priority is given, pick the size with the priority
 *
 * if not, pick the size in the order: thumbnail -> fullsize -> original
 * @param image
 * @param priority
 * @returns
 */
export const pickImageSize = (image?: ImageDto, priority?: ImageSizeType) => {
  if (!image) {
    return null;
  }

  const sizes = image.sizes;

  if (!sizes || sizes.length === 0) {
    return null;
  }

  // Create a copy of the sizes array
  const sizesCopy = [...sizes];

  // Sort the copy
  sizesCopy.sort((a, b) => {
    if (priority) {
      if (a.sizeType === priority) return -1;
      if (b.sizeType === priority) return 1;
    }
    if (a.sizeType === "thumbnail") return -1;
    if (b.sizeType === "thumbnail") return 1;
    if (a.sizeType === "fullsize") return -1;
    if (b.sizeType === "fullsize") return 1;
    return 0;
  });

  return sizesCopy[0];
};
