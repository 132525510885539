import dynamic from "next/dynamic";
import { list } from "radash";
import React from "react";

import { ImpressionTracker } from "@/components/ImpressionTracker";

import { PostDetail } from "@/types/api/post";

import PostListItem from "./PostListItem";
import PostListItemSkeleton from "./PostListItemSkeleton";

const ResponsiveMasonry = dynamic(
  () => import("react-responsive-masonry").then((mod) => mod.ResponsiveMasonry),
  { ssr: false },
);
const Masonry = dynamic(
  () => import("react-responsive-masonry").then((mod) => mod.default),
  { ssr: false },
);

type Props = {
  isInitialLoading?: boolean;
  posts: PostDetail[] | undefined;
  onPostClick?: (postId: string) => void;
};

const PostList = ({ isInitialLoading, posts, onPostClick }: Props) => {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 950: 3 }}>
      {isInitialLoading ? (
        <Masonry gutter={"0.75rem"}>
          {list(5).map((index) => (
            <PostListItemSkeleton key={index} />
          ))}
        </Masonry>
      ) : (
        <Masonry gutter={"0.75rem"}>
          {posts?.map((item, index) => (
            <ImpressionTracker
              key={index}
              postId={item.postId}
              className="w-full"
            >
              <PostListItem
                feed={item}
                index={index}
                onPostClick={() => onPostClick?.(item.postId)}
              />
            </ImpressionTracker>
          ))}
        </Masonry>
      )}
    </ResponsiveMasonry>
  );
};

export default PostList;
