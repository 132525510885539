import { usePostHog } from "posthog-js/react";
import { useCallback } from "react";

type FEED_EVENT = "get_feeds" | "get_feeds_result";
type SEARCH_EVENT = "search_by_user" | "search_result_loaded";
type POST_PAGE_EVENT =
  | "bottom_bar_interact"
  | "comment_interact"
  | "post_interact"
  | "post_click"
  | "post_view";

type CONVERSION_EVENT = "download_blocker_click" | "app_store_button_click";

export type ANALYTICS_EVENT =
  | FEED_EVENT
  | SEARCH_EVENT
  | POST_PAGE_EVENT
  | CONVERSION_EVENT;

const appendMeta = (properties?: Record<string, any>) => {
  return {
    ...properties,
    product: "brek_web",
  };
};

const useAnalytics = () => {
  const posthog = usePostHog();

  const capture = useCallback(
    (event: ANALYTICS_EVENT, properties?: Record<string, any>) => {
      console.info("capture", event, appendMeta(properties));
      posthog.capture(event, appendMeta(properties));
    },
    [posthog],
  );

  const identify = useCallback(
    (distinctId?: string, properties?: Record<string, any>) => {
      posthog.identify(distinctId, appendMeta(properties));
    },
    [posthog],
  );

  const reset = useCallback(() => {
    posthog.reset();
  }, [posthog]);

  return {
    capture,
    identify,
    reset,
  };
};

export default useAnalytics;
