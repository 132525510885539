import { useEffect, useRef } from "react";

import { useImpression } from "./useImpression";

interface UseIntersectionObserverProps {
  postId: string;
  threshold?: number;
}

export const useIntersectionObserver = ({
  postId,
  threshold = 1.0, // 1.0 means fully visible
}: UseIntersectionObserverProps) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const { addImpressions } = useImpression();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // console.debug(
            //   "IntersectionObserver recording impression: ",
            //   postId,
            // );
            addImpressions([postId]);
            observer.unobserve(entry.target); // Stop observing once impression is recorded
          }
        });
      },
      {
        threshold,
        rootMargin: "0px", // No margin, we want exact viewport visibility
      },
    );

    const currentElement = elementRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [postId, threshold, addImpressions]);

  return elementRef;
};
