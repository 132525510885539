import { useCallback } from "react";

import { post } from "@/api/axios";

const IMPRESSION_KEY = "impressions";

let isSending = false;

export const useImpression = () => {
  const addImpressions = useCallback(async (postIds: string[]) => {
    if (typeof window === "undefined") return;

    // console.debug("useImpression adding impressions: ", postIds);

    const existingImpressions = localStorage.getItem(IMPRESSION_KEY);
    const updatedImpressions = new Set([
      ...JSON.parse(existingImpressions || "[]"),
      ...postIds,
    ]);
    localStorage.setItem(
      IMPRESSION_KEY,
      JSON.stringify([...updatedImpressions]),
    );
  }, []);

  const sendImpressionsToBackend = useCallback(async () => {
    if (isSending || typeof window === "undefined") {
      console.debug("Already sending impressions to backend.");
      return;
    }
    isSending = true;

    const sentImpressions = localStorage.getItem(IMPRESSION_KEY);
    if (sentImpressions && JSON.parse(sentImpressions).length > 0) {
      try {
        const res = await post("/impression/update-post-impression", {
          postIds: JSON.parse(sentImpressions),
        });
        const currentImpressions = localStorage.getItem(IMPRESSION_KEY);
        const newImpressions = JSON.parse(currentImpressions || "[]").filter(
          (impression: string) => !sentImpressions.includes(impression),
        );
        localStorage.setItem(IMPRESSION_KEY, JSON.stringify(newImpressions));
      } catch (error) {
        console.error("Failed to send impressions:", error);
      } finally {
        isSending = false;
      }
    } else {
      isSending = false;
    }
  }, []);

  return {
    addImpressions,
    sendImpressionsToBackend,
  };
};
